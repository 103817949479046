/* eslint-disable */
import deepmerge from 'deepmerge';

import axios from 'axios';

import { asyncApi, handleError } from '@/shared/services/api';
import baseApi from '@/shared/services/bright_finder';
import ageGroup from '@/specialist/services/api/age-group';
import announcement from '@/specialist/services/api/announcement';
import assessment from '@/specialist/services/api/assessment';
import assignment from '@/specialist/services/api/assignment';
import attachment from '@/specialist/services/api/attachment';
import bankAccount from '@/specialist/services/api/bank-account';
import business from '@/specialist/services/api/business';
import claim from '@/specialist/services/api/claim';
import complaint from '@/specialist/services/api/complaint';
import course from '@/specialist/services/api/course';
import customView from '@/specialist/services/api/custom-view';
import customViewResult from '@/specialist/services/api/custom-view-result';
import dwolla from '@/specialist/services/api/dwolla';
import eligibilityProgram from '@/specialist/services/api/eligibility-program';
import enrollment from '@/specialist/services/api/enrollment';
import eventAlertConfiguration from '@/specialist/services/api/event-alert-configuration';
import familySubsidy from '@/specialist/services/api/family-subsidy';
import familySubsidyCustomView from '@/specialist/services/api/family-subsidy-custom-view';
import familySubsidyProgram from '@/specialist/services/api/family-subsidy-program';
import form from '@/specialist/services/api/form';
import formCustomView from '@/specialist/services/api/form-custom-view';
import fundingSource from '@/specialist/services/api/funding-source';
import grant from '@/specialist/services/api/grant';
import grantProgram from '@/specialist/services/api/grant_program';
import group from '@/specialist/services/api/group';
import iepReferral from '@/specialist/services/api/iep-referral';
import lesson from '@/specialist/services/api/lesson';
import lessonCompletion from '@/specialist/services/api/lesson-completion';
import license from '@/specialist/services/api/license';
import linkedRecord from '@/specialist/services/api/linked-record';
import member from '@/specialist/services/api/member';
import note from '@/specialist/services/api/note';
import operatingLog from '@/specialist/services/api/operating-log';
import provider from '@/specialist/services/api/provider';
import publicSchema from '@/specialist/services/api/public-schema';
import report from '@/specialist/services/api/report';
import reportDataType from '@/specialist/services/api/report-data-type';
import reportFolder from '@/specialist/services/api/report-folder';
import review from '@/specialist/services/api/review';
import reviewer from '@/specialist/services/api/reviewer';
import schema from '@/specialist/services/api/schema';
import screenerProgram from '@/specialist/services/api/screener-program';
import site from '@/specialist/services/api/site';
import subsidy from '@/specialist/services/api/subsidy';
import subsidyAward from '@/specialist/services/api/subsidy-award';
import subsidyProgram from '@/specialist/services/api/subsidy-program';
import summaryConfiguration from '@/specialist/services/api/summary-configuration';
import taskType from '@/specialist/services/api/task-type';
import team from '@/specialist/services/api/team';
import teamMembership from '@/specialist/services/api/team-membership';
import termsAgreement from '@/specialist/services/api/terms-agreement';
import webpage from '@/specialist/services/api/webpage';
import schemaFunction from '@/specialist/services/api/schema-function';
import schemaFunctionTest from '@/specialist/services/api/schema-function-test';
import schemaFunctionTestSuiteRun from '@/specialist/services/api/schema-function-test-suite-run';
import schemaShare from '@/specialist/services/api/schema-share';
import workflow from '@/specialist/services/api/workflow';

axios.defaults.withCredentials = true;
axios.defaults.headers.get.Accept = 'application/json';
axios.defaults.headers.patch.Accept = 'application/json';
axios.defaults.headers.post.Accept = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.get['X-Location'] = window.location.href;
axios.defaults.headers.get['X-Site'] = import.meta.env.VUE_APP_SITE_ID || window.location.host;
axios.defaults.headers.patch['X-Site'] = import.meta.env.VUE_APP_SITE_ID || window.location.host;
axios.defaults.headers.post['X-Site'] = import.meta.env.VUE_APP_SITE_ID || window.location.host;

const organizationApi = {
  organization: {
    baseUrl() {
      return `${window.api_url}/admin/organization`;
    },

    get(success) {
      return axios.get(this.baseUrl()).then(success);
    },

    update(organization, success) {
      if (!success) return axios.patch(this.baseUrl(), { organization }).catch(handleError);

      return axios.patch(this.baseUrl(), { organization }).then(success).catch(handleError);
    },

    access_request: {
      baseUrl(id) {
        if (id) {
          return `${window.api_url}/organization/access_requests/${id}`;
        }
        return `${window.api_url}/organization/access_requests`;
      },

      create(request, success) {
        axios.post(this.baseUrl(), { access_request: request }).then(success).catch(handleError);
      },

      destroy(id, success) {
        axios.delete(this.baseUrl(id)).then(success).catch(handleError);
      },

      get(id, success) {
        if (!success) return axios.get(this.baseUrl(id));

        return axios.get(this.baseUrl(id)).then(success);
      },

      index(filters, success) {
        if (!success) return axios.get(this.baseUrl(), { params: filters });

        return axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      update(id, request, success) {
        axios.patch(this.baseUrl(id), { access_request: request }).then(success).catch(handleError);
      },
    },

    age_group: ageGroup,

    agreement: {
      baseUrl(id = null) {
        let url = `${window.api_url}/organization/agreements`;
        if (id) url += `/${id}`;
        return url;
      },

      create(agreement, success, failure) {
        if (!success) return axios.post(this.baseUrl(), { agreement });
        axios.post(this.baseUrl(), { agreement }).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(this.baseUrl(id)).then(success);
      },

      downloadUrl(id, filename) {
        return `${this.baseUrl(id)}.pdf?filename=${encodeURIComponent(filename)}.pdf`;
      },

      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      get(id, success) {
        if (!success) return axios.get(this.baseUrl(id));

        axios.get(this.baseUrl(id)).then(success);
      },

      update(id, agreement, success, failure) {
        axios.patch(this.baseUrl(id), { agreement }).then(success).catch(failure);
      },
    },

    announcement,

    assessment,

    asset: {
      base_url() {
        return `${window.api_url}/organization/assets`;
      },

      create(asset, success, failure) {
        axios.post(this.base_url(), { asset }).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(`${this.base_url()}/${id}`).then(success);
      },

      index(params, success) {
        axios.get(this.base_url(), { params }).then(success);
      },

      get(id, success) {
        axios.get(`${this.base_url()}/${id}`).then(success);
      },

      update(id, asset, success, failure) {
        axios.patch(`${this.base_url()}/${id}`, { asset }).then(success).catch(failure);
      },
    },

    assignment,

    assignmentCompletion: asyncApi({
      endpoint: '/organization/assignment_completions',
      payloadKey: 'assignment_completion',
    }),

    assignment_configuration: {
      baseUrl() {
        return `${window.api_url}/admin/assignment_configurations`;
      },

      create(assignmentConfiguration) {
        return axios
          .post(this.baseUrl(), { assignment_configuration: assignmentConfiguration })
          .catch(handleError);
      },

      destroy(assignmentConfiguration) {
        return axios.delete(`${this.baseUrl()}/${assignmentConfiguration.id}`);
      },

      index(filters) {
        return axios.get(this.baseUrl(), { params: filters });
      },

      update(assignmentConfiguration) {
        return axios
          .patch(`${this.baseUrl()}/${assignmentConfiguration.id}`, {
            assignment_configuration: assignmentConfiguration,
          })
          .catch(handleError);
      },
    },

    attachment,

    attendance_log: {
      baseUrl() {
        return `${window.api_url}/organization/attendance_logs`;
      },

      destroy(id, success, failure) {
        axios.delete(`${this.baseUrl()}/${id}`).then(success).catch(failure);
      },

      downloadUrl(filename, operatingLogId) {
        return `${this.baseUrl()}.csv?filename=${filename}.csv&operating_log_id=${operatingLogId}`;
      },

      get(id, success, failure) {
        axios.get(`${this.baseUrl()}/${id}`).then(success).catch(failure);
      },

      index(filters, success, failure) {
        axios.get(this.baseUrl(), { params: filters }).then(success).catch(failure);
      },

      update(id, attendance_log) {
        return axios.patch(`${this.baseUrl()}/${id}`, { attendance_log });
      },
    },

    backgroundCheck: asyncApi({
      endpoint: '/organization/background_checks',
      payloadKey: 'background_check',
    }),

    bankAccount,

    billingStatements: asyncApi({
      endpoint: '/organization/billing_statements',
      payloadKey: 'billing_statement',
    }),

    business,

    claim,

    complaint,

    course,

    course_completion: {
      baseUrl(id = null) {
        let url = `${window.api_url}/organization/course_completions`;
        if (id) url += `/${id}`;
        return url;
      },

      create(resource, success, failure) {
        axios.post(this.baseUrl(), { course_completion: resource }).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(this.baseUrl(id)).then(success);
      },

      downloadUrl(id, filename) {
        const siteId = window.site_id || '';
        return `${this.baseUrl(id)}.pdf?filename=${encodeURIComponent(filename)}.pdf&site_id=${siteId}`;
      },

      index(filters, success) {
        if (!success) return axios.get(this.baseUrl(), { params: filters });

        return axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },

      update(id, resource, success, failure) {
        axios.patch(this.baseUrl(id), { course_completion: resource }).then(success).catch(failure);
      },
    },

    content_template: {
      baseUrl() {
        return `${window.api_url}/organization/content_templates`;
      },

      create(attributes, failure) {
        return axios
          .post(this.baseUrl(), {
            data: {
              type: 'content_template',
              attributes,
            },
          })
          .catch(failure);
      },

      destroy(id) {
        return axios.delete(`${this.baseUrl()}/${id}`);
      },

      index(filters) {
        return axios.get(this.baseUrl(), { params: filters });
      },

      get(id) {
        return axios.get(`${this.baseUrl()}/${id}`);
      },

      update(id, attributes, failure) {
        return axios
          .patch(`${this.baseUrl()}/${id}`, {
            data: {
              type: 'content_template',
              attributes,
            },
          })
          .catch(failure);
      },
    },

    custom_view: customView,

    customViewResult,

    dashboard: {
      baseUrl(id) {
        if (id) {
          return `${window.api_url}/organization/dashboards/${id}`;
        }
        return `${window.api_url}/organization/dashboards`;
      },

      create(dashboard, success, failure) {
        axios.post(this.baseUrl(), dashboard).then(success).catch(failure);
      },

      destroy(dashboardId, success) {
        axios.delete(this.baseUrl(dashboardId)).then(success);
      },

      get(dashboardId, success) {
        axios.get(this.baseUrl(dashboardId)).then(success);
      },

      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      update(id, dashboard, success, failure) {
        axios.patch(this.baseUrl(id), { dashboard }).then(success).catch(failure);
      },
    },

    dwolla,
    eligibilityProgram,
    eventAlertConfiguration,
    enrollment,
    familySubsidyCustomView,
    family_subsidy: familySubsidy,
    family_subsidy_program: familySubsidyProgram,

    favorite: {
      baseUrl() {
        return `${window.api_url}/organization/favorites`;
      },

      create(favorite, success) {
        axios.post(this.baseUrl(), { favorite }).then(success);
      },

      destroy(id, success) {
        axios.delete(`${this.baseUrl()}/${id}`).then(success);
      },

      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },
    },

    form,

    formCustomView,

    fund: {
      baseUrl(id = null) {
        let url = `${window.api_url}/organization/funds`;
        if (id) url += `/${id}`;
        return url;
      },

      create(resource, success, failure) {
        axios.post(this.baseUrl(), { fund: resource }).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(this.baseUrl(id)).then(success);
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },

      index(params, success) {
        if (!success) return axios.get(this.baseUrl(), { params });

        return axios.get(this.baseUrl(), { params }).then(success);
      },

      update(id, resource, success, failure) {
        axios.patch(this.baseUrl(id), { fund: resource }).then(success).catch(failure);
      },
    },

    funding_source: fundingSource,

    funds_transfer: {
      baseUrl() {
        return `${window.api_url}/organization/funds_transfers`;
      },

      create(fundsTransfer, success, failure) {
        axios.post(this.baseUrl(), { funds_transfer: fundsTransfer }).then(success).catch(failure);
      },

      createOrUpdate(fundsTransfer, success, failure) {
        if (fundsTransfer.id) {
          this.update(fundsTransfer.id, fundsTransfer, success, failure);
        } else {
          this.create(fundsTransfer, success, failure);
        }
      },

      destroy(id, success) {
        axios.delete(`${this.baseUrl()}/${id}`).then(success);
      },

      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      get(id, success) {
        axios.get(`${this.baseUrl()}/${id}`).then(success);
      },

      update(id, fundsTransfer, success, failure) {
        axios
          .patch(`${this.baseUrl()}/${id}`, { funds_transfer: fundsTransfer })
          .then(success)
          .catch(failure);
      },
    },

    grant: grant,

    grant_award: {
      base_url(id) {
        if (id) {
          return `${window.api_url}/organization/grant_awards/${id}`;
        }
        return `${window.api_url}/organization/grant_awards`;
      },

      create(grant_award, success, failure) {
        axios.post(this.base_url(), { grant_award }).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(this.base_url(id)).then(success);
      },

      get(id, success) {
        axios.get(this.base_url(id)).then(success);
      },

      index(filters, success) {
        axios.get(this.base_url(), { params: filters }).then(success);
      },

      update(id, grant_award, success, failure) {
        axios.patch(this.base_url(id), { grant_award }).then(success).catch(failure);
      },
    },

    groupActivityType: asyncApi({
      endpoint: '/organization/group_activity_types',
      payloadKey: 'group_activity_type',
    }),

    iep_referral: iepReferral,

    job_config: {
      baseUrl() {
        return `${window.api_url}/organization/job_configs`;
      },

      create(attributes, content_template_id, site_id) {
        return axios
          .post(this.baseUrl(), {
            data: {
              type: 'job_config',
              attributes,
              relationships: {
                content_template: {
                  data: {
                    type: 'content_template',
                    id: content_template_id,
                  },
                },
                site: {
                  data: {
                    type: 'site',
                    id: site_id,
                  },
                },
              },
            },
          })
          .catch(handleError);
      },

      destroy(id) {
        return axios.delete(`${this.baseUrl()}/${id}`);
      },

      index(filters) {
        return axios.get(this.baseUrl(), { params: filters });
      },

      get(id) {
        return axios.get(`${this.baseUrl()}/${id}`);
      },

      update(id, attributes, content_template_id, site_id) {
        return axios
          .patch(`${this.baseUrl()}/${id}`, {
            data: {
              type: 'job_config',
              attributes,
              relationships: {
                content_template: {
                  data: {
                    type: 'content_template',
                    id: content_template_id,
                  },
                },
                site: {
                  data: {
                    type: 'site',
                    id: site_id,
                  },
                },
              },
            },
          })
          .catch(handleError);
      },

      preview(id, phoneNumber) {
        const url = `${this.baseUrl()}/${id}/preview`;
        const data = {
          type: 'job_config',
          attributes: {
            phone: phoneNumber,
          },
        };

        return axios.post(url, { data }).catch(handleError);
      },
    },

    job_run: {
      baseUrl() {
        return `${window.api_url}/organization/job_runs`;
      },

      create(attributes, job_config_id) {
        return axios
          .post(this.baseUrl(), {
            data: {
              type: 'job_run',
              attributes,
              relationships: {
                job_config: {
                  data: {
                    type: 'job_config',
                    id: job_config_id,
                  },
                },
              },
            },
          })
          .catch(handleError);
      },

      index(filters) {
        return axios.get(this.baseUrl(), { params: filters });
      },
    },

    jsonWebToken: asyncApi({ endpoint: '/organization/json_web_tokens' }),

    lesson,

    lessonCompletion,

    license,

    linkedRecord,

    localized_content_template: {
      baseUrl() {
        return `${window.api_url}/organization/localized_content_templates`;
      },

      create(attributes, content_template_id) {
        return axios
          .post(this.baseUrl(), {
            data: {
              type: 'localized_content_template',
              attributes,
              relationships: {
                content_template: {
                  data: {
                    type: 'content_template',
                    id: content_template_id,
                  },
                },
              },
            },
          })
          .catch(handleError);
      },

      destroy(id) {
        return axios.delete(`${this.baseUrl()}/${id}`);
      },

      index(filters) {
        return axios.get(this.baseUrl(), { params: filters });
      },

      get(id) {
        return axios.get(`${this.baseUrl()}/${id}`);
      },

      update(id, attributes) {
        return axios
          .patch(`${this.baseUrl()}/${id}`, {
            data: {
              type: 'localized_content_template',
              attributes,
            },
          })
          .catch(handleError);
      },
    },

    metric: {
      baseUrl(id) {
        if (id) {
          return `${window.api_url}/organization/metrics/${id}`;
        }
        return `${window.api_url}/organization/metrics`;
      },

      create(metric, success, failure) {
        axios.post(this.baseUrl(), metric).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(this.baseUrl(id)).then(success);
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },

      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      update(id, metric, success, failure) {
        axios.patch(this.baseUrl(id), { metric }).then(success).catch(failure);
      },
    },

    schema,

    group,

    group_communications: asyncApi({
      endpoint: '/organization/group_communications',
    }),

    grant_program: grantProgram,

    label: {
      baseUrl(id) {
        if (id) {
          return `${window.api_url}/organization/labels/${id}`;
        }
        return `${window.api_url}/organization/labels`;
      },

      create(params) {
        return axios.post(this.baseUrl(), params).catch(handleError);
      },

      destroy(labelId) {
        return axios.delete(this.baseUrl(labelId)).catch(handleError);
      },

      index(filters) {
        return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
      },

      update(id, label) {
        return axios.patch(this.baseUrl(id), { label }).catch(handleError);
      },
    },

    label_assignment: {
      baseUrl() {
        return `${window.api_url}/organization/label_assignments`;
      },

      create(assignment) {
        return axios.post(this.baseUrl(), { label_assignment: assignment });
      },

      bulk_assign(filters) {
        return axios.patch(this.baseUrl(), filters);
      },

      destroy(filters) {
        return axios.delete(this.baseUrl(), { data: filters });
      },
    },

    list: {
      baseUrl(listId) {
        if (listId) {
          return `${window.api_url}/organization/lists/${listId}`;
        }
        return `${window.api_url}/organization/lists`;
      },

      create(list, success) {
        axios.post(this.baseUrl(), { list }).then(success);
      },

      destroy(listId, success) {
        axios.delete(this.baseUrl(listId)).then(success);
      },

      get(listId, success) {
        axios.get(this.baseUrl(listId)).then(success);
      },

      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      update(listId, list, success) {
        axios.patch(this.baseUrl(listId), { list }).then(success);
      },
    },

    login_code: {
      baseUrl() {
        return `${window.api_url}/organization/login_codes`;
      },

      create(resource, success, failure) {
        axios.post(this.baseUrl(), { login_code: resource }).then(success).catch(failure);
      },
    },

    member,

    messageTemplate: {
      baseUrl(id = null) {
        let url = `${window.api_url}/organization/message_templates`;
        if (id) url += `/${id}`;
        return url;
      },

      create(params, success, failure) {
        axios.post(this.baseUrl(), { message_template: params }).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(`${this.baseUrl()}/${id}`).then(success);
      },

      async index(params = {}, successCallback = null, failureCallback = null) {
        return axios
          .get(this.baseUrl(), { params })
          .then((response) => {
            if (successCallback) successCallback(response);
            return response;
          })
          .catch((error) => {
            if (failureCallback) return failureCallback(error);
            return handleError(error);
          });
      },

      get(id, success) {
        axios.get(`${this.baseUrl()}/${id}`).then(success);
      },

      htmlPreviewUrl(id, lang) {
        return `${this.baseUrl()}/${id}.html?lang=${lang}`;
      },

      update(id, message_template, success, failure) {
        axios.patch(`${this.baseUrl()}/${id}`, { message_template }).then(success).catch(failure);
      },
    },

    message: {
      baseUrl() {
        return `${window.api_url}/organization/messages`;
      },

      create(message, success, failure) {
        if (!success) return axios.post(this.baseUrl(), { message });

        return axios.post(this.baseUrl(), { message }).then(success).catch(failure);
      },

      get(id, success) {
        if (!success) return axios.get(`${this.baseUrl()}/${id}`);

        return axios.get(`${this.baseUrl()}/${id}`).then(success);
      },

      index(filters, success) {
        if (!success) return axios.get(this.baseUrl(), { params: filters });

        return axios.get(this.baseUrl(), { params: filters }).then(success);
      },
    },

    note,

    operating_log: operatingLog,

    ownership_request: {
      baseUrl(id) {
        if (id) {
          return `${window.api_url}/organization/ownership_requests/${id}`;
        }
        return `${window.api_url}/organization/ownership_requests`;
      },

      create(request, success) {
        axios.post(this.baseUrl(), { ownership_request: request }).then(success);
      },

      destroy(id, success) {
        axios.delete(this.baseUrl(id)).then(success);
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },

      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      update(id, request, success) {
        axios.patch(this.baseUrl(id), { ownership_request: request }).then(success);
      },
    },

    payment: {
      baseUrl() {
        return `${window.api_url}/organization/payments`;
      },

      downloadUrl(filters) {
        // eslint-disable-next-line no-unused-vars
        const params = Object.fromEntries(Object.entries(filters).filter(([_k, v]) => v != null));
        params.filename = `Payments Export ${new Date().toString()}.csv`;
        return [`${this.baseUrl()}.csv`, new URLSearchParams(params).toString()].join('?');
      },

      index(filters) {
        return axios.get(this.baseUrl(), { params: filters });
      },

      get(id) {
        return axios.get(`${this.baseUrl()}/${id}`);
      },

      update(id, payment, success, failure) {
        if (success) {
          return axios.patch(`${this.baseUrl()}/${id}`, { payment }).then(success).catch(failure);
        }

        return axios.patch(`${this.baseUrl()}/${id}`, { payment });
      },
    },

    payment_adjustment: {
      baseUrl() {
        return `${window.api_url}/organization/payment_adjustments`;
      },

      create(paymentAdjustment, success, failure) {
        axios
          .post(this.baseUrl(), { payment_adjustment: paymentAdjustment })
          .then(success)
          .catch(failure);
      },

      createOrUpdate(paymentAdjustment, success, failure) {
        if (paymentAdjustment.id) {
          this.update(paymentAdjustment.id, paymentAdjustment, success, failure);
        } else {
          this.create(paymentAdjustment, success, failure);
        }
      },

      destroy(id, success) {
        axios.delete(`${this.baseUrl()}/${id}`).then(success);
      },

      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      get(id, success) {
        axios.get(`${this.baseUrl()}/${id}`).then(success);
      },

      update(id, paymentAdjustment, success, failure) {
        axios
          .patch(`${this.baseUrl()}/${id}`, { payment_adjustment: paymentAdjustment })
          .then(success)
          .catch(failure);
      },
    },

    permission: {
      baseUrl() {
        return `${window.api_url}/organization/permissions`;
      },

      create(permission, success, failure) {
        axios.post(this.baseUrl(), { permission }).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(`${this.baseUrl()}/${id}`).then(success);
      },

      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      get(id, success) {
        axios.get(`${this.baseUrl()}/${id}`).then(success);
      },

      update(id, permission, success, failure) {
        axios.patch(`${this.baseUrl()}/${id}`, { permission }).then(success).catch(failure);
      },
    },

    profile: {
      base_url() {
        return `${window.api_url}/organization/profile`;
      },

      get(success) {
        axios
          .get(this.base_url(), {
            headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
            withCredentials: true,
          })
          .then(success);
      },

      update(profile, success, failure) {
        axios.patch(this.base_url(), { profile }).then(success).catch(failure);
      },
    },

    provider,

    public_schema: publicSchema,

    report,
    reportDataType,
    reportFolder,
    review,
    reviewer,

    revision: {
      base_url() {
        return `${window.api_url}/organization/revisions`;
      },

      index(filters, success) {
        if (!success) return axios.get(this.base_url(), { params: filters });

        return axios.get(this.base_url(), { params: filters }).then(success);
      },

      show(revisionId, success) {
        axios.get(`${this.base_url()}/${revisionId}`).then(success);
      },

      update(revisionId, revision, success) {
        axios.patch(`${this.base_url()}/${revisionId}`, { revision }).then(success);
      },
    },

    revision_view: {
      baseUrl() {
        return `${window.api_url}/organization/revision_views`;
      },

      create(resource, success, failure) {
        axios.post(this.baseUrl(), { revision_view: resource }).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(`${this.baseUrl()}/${id}`).then(success);
      },

      index(params, success) {
        axios.get(this.baseUrl(), { params }).then(success);
      },

      get(id, success) {
        axios.get(`${this.baseUrl()}/${id}`).then(success);
      },

      update(id, resource, success, failure) {
        axios
          .patch(`${this.baseUrl()}/${id}`, { revision_view: resource })
          .then(success)
          .catch(failure);
      },
    },

    screenerProgram,

    search: {
      baseUrl(searchId) {
        if (searchId) {
          return `${window.api_url}/organization/searches/${searchId}`;
        }
        return `${window.api_url}/organization/searches`;
      },

      create(search, success, error) {
        axios.post(this.baseUrl(), { search }).then(success).catch(error);
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },

      index(success, failure) {
        axios.get(this.baseUrl()).then(success).catch(failure);
      },

      match: {
        baseUrl(searchId) {
          return `${window.api_url}/organization/searches/${searchId}/matches`;
        },

        index(searchId, params, success) {
          axios.get(this.baseUrl(searchId), { params }).then(success);
        },
      },

      update(id, search, success, failure) {
        axios.patch(this.baseUrl(id), { search }).then(success).catch(failure);
      },
    },

    schema_function: schemaFunction,
    schema_function_test: schemaFunctionTest,
    schema_function_test_suite_run: schemaFunctionTestSuiteRun,
    schema_share: schemaShare,

    seat: {
      baseUrl() {
        return `${window.api_url}/organization/seats`;
      },

      create(seat) {
        return axios.post(this.baseUrl(), { seat });
      },

      destroy(seatId) {
        return axios.delete(`${this.baseUrl()}/${seatId}`);
      },

      index(subsidyProgramId, programIds) {
        return axios.get(
          `${this.baseUrl(subsidyProgramId)}?subsidy_program_id=${subsidyProgramId}&program_ids=${programIds}`,
        );
      },

      update(seat) {
        return axios.patch(`${this.baseUrl()}/${seat.id}`, { seat });
      },
    },

    sent_email: asyncApi({
      endpoint: '/organization/sent_emails',
    }),

    sent_sms: asyncApi({
      endpoint: '/organization/sent_sms',
    }),

    site,

    siteTemplate: {
      baseUrl(id = null) {
        let url = `${window.api_url}/organization/site_templates`;
        if (id) url += `/${id}`;
        return url;
      },

      create(site_template, success, failure) {
        axios.post(this.baseUrl(), site_template).then(success).catch(failure);
      },

      pageTemplate: {
        url(siteTemplateId, pageTemplateId) {
          if (pageTemplateId) {
            return `${window.api_url}/organization/site_templates/${siteTemplateId}/page_templates/${pageTemplateId}`;
          }
          return `${window.api_url}/organization/site_templates/${siteTemplateId}/page_templates`;
        },

        create(siteTemplateId, page_template, success, failure) {
          axios.post(this.url(siteTemplateId), { page_template }).then(success).catch(failure);
        },

        destroy(siteTemplateId, pageTemplateId, success) {
          axios.delete(this.url(siteTemplateId, pageTemplateId)).then(success);
        },

        index(siteTemplateId, success) {
          axios.get(this.url(siteTemplateId)).then(success);
        },

        get(siteTemplateId, pageTemplateId, success) {
          axios.get(this.url(siteTemplateId, pageTemplateId)).then(success);
        },

        update(siteTemplateId, pageTemplateId, page_template, success, failure) {
          axios
            .patch(this.url(siteTemplateId, pageTemplateId), { page_template })
            .then(success)
            .catch(failure);
        },

        updateOrCreate(siteTemplateId, page_template, success, failure) {
          if (page_template.id) {
            this.update(siteTemplateId, page_template.id, page_template, success, failure);
          } else {
            this.create(siteTemplateId, page_template, success, failure);
          }
        },
      },

      destroy(siteTemplateId, success) {
        axios.delete(this.baseUrl(siteTemplateId)).then(success);
      },

      get(siteTemplateId, success) {
        if (!success) return axios.get(this.baseUrl(siteTemplateId));

        axios.get(this.baseUrl(siteTemplateId)).then(success);
      },

      async index(params = {}, successCallback = null, failureCallback = null) {
        return axios
          .get(this.baseUrl(), { params })
          .then((response) => {
            if (successCallback) successCallback(response);
            return response;
          })
          .catch((error) => {
            if (failureCallback) return failureCallback(error);
            return handleError(error);
          });
      },

      update(siteTemplateId, site_template, success, failure) {
        axios.patch(this.baseUrl(siteTemplateId), { site_template }).then(success).catch(failure);
      },

      updateOrCreate(site_template, success, failure) {
        if (site_template.id) {
          this.update(site_template.id, site_template, success, failure);
        } else {
          this.create(site_template, success, failure);
        }
      },
    },

    subsidy,

    subsidy_award: subsidyAward,

    subsidy_program: subsidyProgram,

    summary_configuration: summaryConfiguration,

    survey: {
      baseUrl(id) {
        const base = `${window.api_url}/organization/surveys`;
        return id ? `${base}/${id}` : base;
      },

      destroy(id) {
        return axios.delete(this.baseUrl(id));
      },

      index(filters) {
        return axios.get(this.baseUrl(), { params: filters });
      },

      get(id) {
        return axios.get(this.baseUrl(id));
      },
    },

    task: asyncApi({
      endpoint: '/organization/tasks',
      payloadKey: 'task',
    }),

    taskType,

    team,

    teamMembership,

    termsAgreement,

    translation: {
      url(id) {
        if (id) {
          return `${window.api_url}/organization/translations/${id}`;
        }
        return `${window.api_url}/organization/translations`;
      },

      create(translation, success, failure) {
        axios.post(this.url(), translation).then(success).catch(failure);
      },

      destroy(translationId, success) {
        axios.delete(this.url(translationId)).then(success);
      },

      downloadUrl(filename) {
        return `${this.url()}.csv?filename=${filename}`;
      },

      get(translationId, success) {
        axios.get(this.url(translationId)).then(success);
      },

      index(params, success) {
        axios.get(this.url(), { params }).then(success);
      },

      update(id, translation, success, failure) {
        axios.patch(this.url(id), { translation }).then(success).catch(failure);
      },

      updateOrCreate(translation, success, failure) {
        if (translation.id) {
          this.update(translation.id, translation, success, failure);
        } else {
          this.create(translation, success, failure);
        }
      },

      upload: {
        baseUrl() {
          return `${window.api_url}/organization/translations/uploads`;
        },

        create(upload) {
          return axios.post(this.baseUrl(), { upload }).catch(handleError);
        },
      },
    },

    upload: {
      base_url() {
        return `${window.api_url}/organization/uploads`;
      },

      create(upload, success, failure) {
        axios.post(this.base_url(), { upload }).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(`${this.base_url()}/${id}`).then(success);
      },

      get(id) {
        return axios.get(`${this.base_url()}/${id}`);
      },

      index(params) {
        return axios.get(this.base_url(), { params });
      },

      run(id) {
        return axios.patch(`${this.base_url()}/${id}/run`);
      },

      update(id, upload, success, failure) {
        axios.patch(`${this.base_url()}/${id}`, { upload }).then(success).catch(failure);
      },
    },

    vault: {
      baseUrl(id) {
        let url = `${window.api_url}/organization/vaults`;
        if (id) {
          url += `/${id}`;
        }
        return url;
      },

      create(vault, success, failure) {
        axios.post(this.baseUrl(), { vault }).then(success).catch(failure);
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },

      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      update(id, vault, success) {
        return axios.patch(this.baseUrl(id), { vault }).then(success);
      },

      promiseUpdate(id, vault) {
        return axios.patch(this.baseUrl(id), { vault });
      },
    },

    webpage,

    workflow,

    worksheet: {
      baseUrl(id = null) {
        let url = `${window.api_url}/organization/worksheets`;
        if (id) url += `/${id}`;
        return url;
      },

      create(resource, success, failure) {
        axios.post(this.baseUrl(), { worksheet: resource }).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(this.baseUrl(id)).then(success);
      },

      downloadUrl(id, filename) {
        return `${this.baseUrl(id)}.pdf?filename=${encodeURIComponent(filename)}.pdf`;
      },

      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },

      update(id, resource, success, failure) {
        axios.patch(this.baseUrl(id), { worksheet: resource }).then(success).catch(failure);
      },
    },
  },
};

export default deepmerge.all([baseApi, organizationApi]);
