import deepmerge from 'deepmerge';

import axios from 'axios';
import { asyncApi, handleError } from '@/shared/services/api';
import baseApi from '@/shared/services/bright_finder';
import accessRequest from '@/manager/services/api/access-request';
import attachment from '@/manager/services/api/attachment';
import claim from '@/manager/services/api/claim';
import enrollment from '@/manager/services/api/enrollment';
import form from '@/manager/services/api/form';
import grant from '@/manager/services/api/grant';
import member from '@/manager/services/api/member';
import operatingLog from '@/manager/services/api/operating-log';
import ownershipRequest from '@/manager/services/api/ownership-request';
import profile from '@/manager/services/api/profile';
import provider from '@/manager/services/api/provider';
import seat from '@/manager/services/api/seat';

axios.defaults.withCredentials = true;
axios.defaults.headers.get.Accept = 'application/json';
axios.defaults.headers.patch.Accept = 'application/json';
axios.defaults.headers.post.Accept = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.get['X-Location'] = window.location.href;
axios.defaults.headers.get['X-Site'] = import.meta.env.VUE_APP_SITE_ID || window.location.host;
axios.defaults.headers.patch['X-Site'] = import.meta.env.VUE_APP_SITE_ID || window.location.host;
axios.defaults.headers.post['X-Site'] = import.meta.env.VUE_APP_SITE_ID || window.location.host;

const managerApi = {
  manager: {
    access_request: accessRequest,

    agreement: {
      baseUrl() {
        return `${window.api_url}/manager/agreements`;
      },

      get(id, success, failure) {
        if (!success) return axios.get(`${this.baseUrl()}/${id}`);

        axios.get(`${this.baseUrl()}/${id}`).then(success).catch(failure);
      },

      index(filters, success, failure) {
        axios.get(this.baseUrl(), { params: filters }).then(success).catch(failure);
      },

      update(id, agreement, success, failure) {
        axios.patch(`${this.baseUrl()}/${id}`, { agreement }).then(success).catch(failure);
      },
    },

    assessment: {
      baseUrl(id = null) {
        let url = `${window.api_url}/manager/assessments`;
        if (id) url += `/${id}`;
        return url;
      },

      create(resource, success, failure) {
        axios.post(this.baseUrl(), { assessment: resource }).then(success).catch(failure);
      },

      destroy(id, success) {
        axios.delete(this.baseUrl(id)).then(success);
      },

      downloadUrl(id, filename) {
        return `${this.baseUrl(id)}.pdf?filename=${encodeURIComponent(filename)}.pdf`;
      },

      index(filters, success) {
        if (success) {
          return axios.get(this.baseUrl(), { params: filters }).then(success).catch(handleError);
        }

        return axios.get(this.baseUrl(), { params: filters });
      },

      get(id, success) {
        if (success) {
          return axios.get(`${this.baseUrl()}/${id}`).then(success).catch(handleError);
        }

        return axios.get(`${this.baseUrl()}/${id}`);
      },

      update(id, resource, success, failure) {
        if (success) {
          return axios
            .patch(`${this.baseUrl()}/${id}`, { assessment: resource })
            .then(success)
            .catch(failure);
        }

        return axios.patch(`${this.baseUrl()}/${id}`, { assessment: resource });
      },
    },

    attachment,

    attendance_log: {
      baseUrl() {
        return `${window.api_url}/manager/attendance_logs`;
      },

      destroy(id, success, failure) {
        axios.delete(`${this.baseUrl()}/${id}`).then(success).catch(failure);
      },

      downloadUrl(filename, operatingLogId) {
        return `${this.baseUrl()}.csv?filename=${filename}.csv&operating_log_id=${operatingLogId}`;
      },

      get(id, success, failure) {
        axios.get(`${this.baseUrl()}/${id}`).then(success).catch(failure);
      },

      index(filters, success, failure) {
        axios.get(this.baseUrl(), { params: filters }).then(success).catch(failure);
      },

      update(id, attendance_log) {
        return axios.patch(`${this.baseUrl()}/${id}`, { attendance_log });
      },
    },

    billingStatements: asyncApi({
      endpoint: '/manager/billing_statements',
      payloadKey: 'billing_statement',
    }),

    business: asyncApi({
      endpoint: '/manager/business',
      payloadKey: 'business',
    }),

    claim: claim,

    course_completion: {
      baseUrl(id = null) {
        let url = `${window.api_url}/manager/course_completions`;
        if (id) url += `/${id}`;
        return url;
      },

      create(course_completion) {
        return axios.post(this.baseUrl(), { course_completion });
      },

      downloadUrl(id, filename) {
        const siteId = window.site_id || '';
        return `${this.baseUrl(id)}.pdf?filename=${encodeURIComponent(filename)}.pdf&site_id=${siteId}`;
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },

      index(params, success) {
        if (success) {
          return axios.get(this.baseUrl(), { params }).then(success);
        }

        return axios.get(this.baseUrl(), { params });
      },
    },

    enrollment: enrollment,

    form: form,

    grant: grant,

    mass_message: {
      baseUrl() {
        return `${window.api_url}/manager/mass_messages`;
      },

      create(massMessage, success, failure) {
        axios.post(this.baseUrl(), { mass_message: massMessage }).then(success).catch(failure);
      },

      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      get(id, success) {
        axios.get(`${this.baseUrl()}/${id}`).then(success);
      },
    },

    member,

    message: {
      baseUrl() {
        return `${window.api_url}/manager/messages`;
      },

      create(message, success, failure) {
        axios.post(this.baseUrl(), { message }).then(success).catch(failure);
      },

      get(id, success) {
        axios.get(`${this.baseUrl()}/${id}`).then(success);
      },

      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      promiseCreate(message) {
        return axios.post(this.baseUrl(), { message });
      },
    },

    operating_log: operatingLog,

    ownership_request: ownershipRequest,

    payment: asyncApi({
      endpoint: '/manager/payments',
      payloadKey: 'payment',
    }),

    payment_adjustment: asyncApi({
      endpoint: '/manager/payment_adjustments',
      payloadKey: 'payment_adjustment',
    }),

    profile,

    provider,

    quiz: {
      baseUrl(id) {
        if (id) {
          return `${window.api_url}/manager/quizzes/${id}`;
        }
        return `${window.api_url}/manager/quizzes`;
      },

      create(quiz, success, failure) {
        axios.post(this.baseUrl(), { quiz }).then(success).catch(failure);
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },

      index(params, success) {
        axios.get(this.baseUrl(), { params }).then(success);
      },

      update(id, quiz, success, failure) {
        axios.patch(this.baseUrl(id), { quiz }).then(success).catch(failure);
      },

      promiseUpdate(id, quiz) {
        return axios.patch(this.baseUrl(id), { quiz });
      },
    },

    seat,

    subsidy: {
      baseUrl(id = null) {
        let url = `${window.api_url}/manager/subsidies`;
        if (id) url += `/${id}`;
        return url;
      },

      downloadUrl(id, locale, filename) {
        return `${this.baseUrl(id)}.pdf?filename=${encodeURIComponent(filename)}.pdf&locale=${locale}`;
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },
    },

    subsidy_award: {
      baseUrl(id) {
        return `${window.api_url}/manager/subsidy_awards/${id || ''}`;
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },

      index(filters, success) {
        axios.get(this.baseUrl(), { params: filters }).then(success);
      },
    },

    tourReservation: asyncApi({
      endpoint: '/manager/tour_reservations',
      payloadKey: 'tour_reservation',
    }),

    worksheet: {
      baseUrl() {
        return `${window.api_url}/manager/worksheets`;
      },

      index(filters, success) {
        if (success) {
          return axios.get(this.baseUrl(), { params: filters }).then(success).catch(handleError);
        }

        return axios.get(this.baseUrl(), { params: filters }).then(success);
      },

      get(id, success) {
        if (success) {
          return axios.get(`${this.baseUrl()}/${id}`).then(success).catch(handleError);
        }

        return axios.get(`${this.baseUrl()}/${id}`);
      },

      update(id, assessmentId, resource, success, failure) {
        if (success) {
          return axios
            .patch(`${this.baseUrl()}/${id}?assessment_id=${assessmentId}`, { worksheet: resource })
            .then(success)
            .catch(failure);
        }

        return axios.patch(`${this.baseUrl()}/${id}?assessment_id=${assessmentId}`, {
          worksheet: resource,
        });
      },
    },
  },
};

export default deepmerge.all([baseApi, managerApi]);
